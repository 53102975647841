import { request } from '@/utils/service'
import type * as Login from './types/login'

/** 获取登录验证码 */
export function getLoginCodeApi() {
  return request<Login.LoginCodeResponseData>({
    url: 'login/code',
    method: 'get',
  })
}

/** 登录并返回 Token */
export function loginApi(params: Login.LoginRequestData) {
  return request<Login.LoginResponseData>({
    url: 'UserApi/Login/ClinicLoginByAccount',
    method: 'get',
    params,
  })
}

/** 获取用户详情 */
export function getUserInfoApi() {
  return request<Login.UserInfoResponseData>({
    url: 'users/info',
    method: 'get',
  })
}

/** 平台登录并返回 Token */
export function adminLoginByAccount(params: Login.LoginRequestData) {
  return request<Login.LoginResponseData>({
    url: 'UserApi/Login/AdminLoginByAccount',
    method: 'post',
    params,
  })
}
