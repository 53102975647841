/** 统一处理 Cookie */

// import { CHANNEL_NAME } from '@/constants/cache-key'

import CacheKey from '@/constants/cache-key'
import Cookies from 'js-cookie'

export const getToken = () => {
  return Cookies.get(CacheKey.TOKEN)
}
export const setToken = (token: string) => {
  // const bc = new BroadcastChannel(CHANNEL_NAME)
  // bc.postMessage({ cookieValue: token })
  Cookies.set(CacheKey.TOKEN, token)
}
export const removeToken = () => {
  Cookies.remove(CacheKey.TOKEN)
}
