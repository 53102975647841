// import { storeToRefs } from 'pinia'
import { ref, onMounted } from 'vue'

export function useBaidu() {
  const AK = 'q6jlOl4vpnN5WTI6gueLEzYGsZ65ASRo'
  const BMap_URL =
    '//api.map.baidu.com/api?type=webgl&v=3.0&ak=' +
    AK +
    '&s=1&callback=onBMapCallback'
  // 如果已加载直接返回
  if (typeof window.BMapGL !== 'undefined') {
    return true
  }
  console.log('初始化百度地图脚本...')
  // 百度地图异步加载回调处理
  window.onBMapCallback = function () {
    console.log('百度地图脚本初始化成功...')
  }
  // 插入script脚本
  const scriptNode = document.createElement('script')
  scriptNode.setAttribute('type', 'text/javascript')
  scriptNode.setAttribute('src', BMap_URL)
  document.body.appendChild(scriptNode)
}
