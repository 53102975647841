<template>
  <svg :class="className" aria-hidden="true">
    <use :xlink:href="symbolId" rel="external nofollow" :fill="color" />
  </svg>
</template>

<script setup lang="ts">
import { computed } from 'vue'

const props = defineProps({
  name: {
    type: String,
    required: true,
  },
  color: {
    type: String,
    default: '#4D4D4D',
  },
})

const symbolId = computed(() => {
  return `#icon-${props.name}`
})

const className = computed(() => {
  return `svg-icon icon-${props.name}`
})
</script>

<style lang="scss" scoped>
.svg-icon {
  width: 1em;
  height: 1em;
  vertical-align: -0.15em;
  fill: currentColor;
  overflow: hidden;
}
</style>
