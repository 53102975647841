/** 设备类型 */
export enum DeviceEnum {
  Mobile,
  Desktop
}

/** 侧边栏打开状态常量 */
export const SIDEBAR_OPENED = "opened"
/** 侧边栏关闭状态常量 */
export const SIDEBAR_CLOSED = "closed"
// 菜单列表取值parentId
// 10000:DoctorClient,管理端;
// 20000:ClinicManage,医师端;
export const MENU_1_ID = "10000"
export const MENU_2_ID = "20000"

export type SidebarOpened = typeof SIDEBAR_OPENED
export type SidebarClosed = typeof SIDEBAR_CLOSED
