import { ref } from "vue"
import store from "@/store"
import { defineStore } from "pinia"
import { type RouteRecordRaw } from "vue-router"
import { constantRoutes, asyncRoutes } from "@/router"
import { flatMultiLevelRoutes } from "@/router/helper"
import routeSettings from "@/config/route"

const hasPermission = (roles: string[], route: RouteRecordRaw) => {
  const routeRoles = route.meta?.roles
  return routeRoles ? roles.some((role) => routeRoles.includes(role)) : true
}

// 递归遍历动态路由
// ps: controlled: true // 路由是否受權限控制
const filterAsyncRoutes = (
  routes: RouteRecordRaw[],
  roles: string[],
  routeMap: Record<string, any>,
  parentRoute?: string
) => {
  const res: RouteRecordRaw[] = []
  console.log("routes == ", routes)
  console.log("roles == ", roles)
  console.log("routeMap == ", routeMap)
  routes.forEach((route) => {
    const tempRoute = { ...route }
    // if (hasPermission(roles, tempRoute)) {
    //   if (tempRoute.children) {
    //     tempRoute.children = filterAsyncRoutes(tempRoute.children, roles, routeMap)
    //   }
    //   res.push(tempRoute)
    // }
    let pathStr = ""
    if (parentRoute) {
      pathStr = `${parentRoute}/${route.path.replace("/", "")}`
    } else {
      pathStr = route.path.replace("/", "")
    }
    // console.log(
    //   "parentRoute == ",
    //   parentRoute,
    //   route.path.replace("/", ""),
    //   `${parentRoute}/${route.path.replace("/", "")}`
    // )
    console.log("route == ", route)
    console.log("tempRoute == ", tempRoute)
    console.log("pathStr == ", pathStr)
    console.log("routeMap == ", routeMap)
    console.log("routeMap[pathStr] == ", routeMap[pathStr])
    if (routeMap[pathStr] && routeMap[pathStr]?.code?.includes?.("view")) {
      // if (routeMap[pathStr].grandRoute) {
      //   if (routeMap[routeMap[pathStr].grandRoute]) {

      //   }
      // }
      if (tempRoute.children) {
        tempRoute.children = filterAsyncRoutes(tempRoute.children, roles, routeMap, pathStr)
      }
      console.log("routeMap.title == ", routeMap.title, tempRoute.meta?.title)
      if (routeMap[pathStr].title && tempRoute.meta?.title) {
        tempRoute.meta.title = routeMap[pathStr].title
      }
      res.push(tempRoute)
    }
  })
  return res
}

export const usePermissionStore = defineStore("permission", () => {
  const routes = ref<RouteRecordRaw[]>([])
  const dynamicRoutes = ref<RouteRecordRaw[]>([])

  // 设置最终路由tree
  const setRoutes = (roles: string[], routeMap: Record<string, any>) => {
    const accessedRoutes = routeSettings.async ? filterAsyncRoutes(asyncRoutes, roles, routeMap) : asyncRoutes
    console.log("constantRoutes == ", constantRoutes)
    console.log("accessedRoutes == ", accessedRoutes)
    routes.value = constantRoutes.concat(accessedRoutes)
    // 路由降级
    dynamicRoutes.value = routeSettings.thirdLevelRouteCache ? flatMultiLevelRoutes(accessedRoutes) : accessedRoutes
  }

  return { routes, dynamicRoutes, setRoutes }
})

/** 在 setup 外使用 */
export function usePermissionStoreHook() {
  return usePermissionStore(store)
}
