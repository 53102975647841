import { ref } from 'vue'
import store from '@/store'
import { defineStore } from 'pinia'
import { usePermissionStore } from './permission'
import { useTagsViewStore } from './tags-view'
import { useSettingsStore } from './settings'
import { getToken, removeToken, setToken } from '@/utils/cache/cookies'
import {
  getUserInfo,
  setUserInfo,
  getStaffMenuTree,
  setStaffMenuTree,
} from '@/utils/cache/local-storage'
import router, { resetRouter } from '@/router'
// 方法
import { toTree, flatFunc } from '@/utils/tree'
// api
import { adminLoginByAccount } from '@/api/login'
import { getLoginUser, getStaffMenu } from '@/api/user'
// 常量
import { MENU_1_ID } from '@/constants/app-key'
import { type LoginRequestData } from '@/api/login/types/login'
import { type RouteRecordRaw } from 'vue-router'
import routeSettings from '@/config/route'

export const useUserStore = defineStore('user', () => {
  const routeMap = ref<Record<string, any>>({})
  const token = ref<string>(getToken() || '')
  const roles = ref<string[]>([])
  const account = ref<string>('')
  // 用戶信息
  const userInfo = ref<Record<string, any>>({})
  // 路由权限
  const staffMenuInfo = ref<Record<string, any>>({})

  const permissionStore = usePermissionStore()
  const tagsViewStore = useTagsViewStore()
  const settingsStore = useSettingsStore()

  /** 设置角色数组 */
  const setRoles = (value: string[]) => {
    roles.value = value
  }
  /** 登录 */
  const login = async ({ account, password }: LoginRequestData) => {
    const { accessToken } = await adminLoginByAccount({ account, password })
    setToken(accessToken)
    token.value = accessToken
  }
  /** 获取用户详情 */
  const getInfo = async () => {
    // const { data } = await getUserInfoApi()
    // userInfo.value = await getLoginUser() // 用户名称等信息
    // 用户名称等信息
    const loginStoreUserInfo = getUserInfo()
    if (loginStoreUserInfo) {
      userInfo.value = loginStoreUserInfo
    } else {
      userInfo.value = await getLoginUser()
      setUserInfo(userInfo.value)
    }
    console.log('loginStoreUserInfo == ', loginStoreUserInfo)
    // 用户可用菜单
    let staffMenuList = getStaffMenuTree()
    console.log('staffMenuList == ', staffMenuList)
    if (!staffMenuList) {
      staffMenuList = await getStaffMenu({
        menuCategory: MENU_1_ID,
      })
      setStaffMenuTree(staffMenuList)
    }
    // const staffMenuList = await getStaffMenu({
    //   menuCategory: MENU_1_ID
    // }) // 用户可用菜单
    // staffMenuList.map

    const staffMenuTree = toTree(staffMenuList, MENU_1_ID)
    flatFunc(routeMap.value, staffMenuTree)
    console.log('staffMenuList staffMenuTree == ', staffMenuList, staffMenuTree)
    console.log('routeMap.value == ', routeMap.value)

    const data = {
      account: 'admin',
      roles: ['admin'],
    }
    account.value = userInfo.value.userName
    // 验证返回的 roles 是否为一个非空数组，否则塞入一个没有任何作用的默认角色，防止路由守卫逻辑进入无限循环
    roles.value =
      data.roles?.length > 0 ? data.roles : routeSettings.defaultRoles
    // roles.value = userInfo.value?.sysRole ? [userInfo.value?.sysRole] : routeSettings.defaultRoles
  }
  /** 切换角色 */
  const changeRoles = async (role: string) => {
    const newToken = 'token-' + role
    token.value = newToken
    setToken(newToken)
    await getInfo()
    console.log('bb routeMap.value == ', routeMap.value)
    permissionStore.setRoutes(roles.value, routeMap.value)
    resetRouter()
    permissionStore.dynamicRoutes.forEach((item: RouteRecordRaw) => {
      router.addRoute(item)
    })
    _resetTagsView()
  }
  /** 登出 */
  const logout = () => {
    removeToken()
    token.value = ''
    roles.value = []
    userInfo.value = {}
    routeMap.value = {}
    setUserInfo()
    setStaffMenuTree()
    resetRouter()
    _resetTagsView()
  }
  /** 重置 Token */
  const resetToken = () => {
    removeToken()
    token.value = ''
    roles.value = []
  }
  /** 重置 Visited Views 和 Cached Views */
  const _resetTagsView = () => {
    if (!settingsStore.cacheTagsView) {
      tagsViewStore.delAllVisitedViews()
      tagsViewStore.delAllCachedViews()
    }
  }

  return {
    token,
    roles,
    account,
    routeMap,
    userInfo,
    setRoles,
    login,
    getInfo,
    changeRoles,
    logout,
    resetToken,
  }
})

/** 在 setup 外使用 */
export function useUserStoreHook() {
  return useUserStore(store)
}
