export const HttpErrInfo = {
  // 患者删除但已有挂号
  ExistDataForbidDelete: '该用户存在挂号信息，不可删除',
  // 用戶已存在
  StaffExist: `该用戶已存在`,
  // 证件已存在
  IdNoExist: `该证件号码已被使用`,
  // 账号已存在
  AccountExist: `该账号已被使用`,
  // 账号或密码错误
  AccountOrPasswordError: `账号或密码错误`,
  // 存在未使用的优惠券
  ExistNotUse: `该优惠券已被用户领取且未被使用，无法停用`,
  // 不可修改
  NotModify: `不可修改`,
  // 不可删除
  NotDelete: `不可删除`,
  // 不可包括
  NotSupported: `优惠券单据类型不可以包括文档影像单、快递单`,
  // 编码已存在
  CodeAlreadyExists: `诊所编码已存在`,
  // 条码已存在
  BarcodeExist: `该药品条码已存在`,
  // 规格编码已存在
  SkuCodeExist: `该规格编码已存在`,
}
// () => {
//   // t: (arg0?: string) => void, message
//   return
// }
